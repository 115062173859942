<div class="row first-row">
    <!-- <custom-menu position="footer" class="first-menu" [horizontal]="true"></custom-menu>4 -->
    <!-- <loading-indicator [isVisible]="loading$ | async" class="overlay"></loading-indicator> -->
<link href="https://res.cloudinary.com/dpyy9uysx/raw/upload/v1582801042/maxpay/bootstrap.min.css" rel="stylesheet">
<footer class="footer">
    <div>
    <div class="content has-text-centered">
     
     <!-- <a href="/contact" class="button mat-button">
            <span class="mat-button-wrapper">Contact </span></a> -->
            <div class="row" style="display: flex;justify-content: space-between;">

              <div>
                  <a href="/contact" class="mat-button">
                    <span class="mat-button-wrapper">Contact</span></a>
              </div>
              <div>
                  <a href="https://polarbuilder.com/legal/tos.html" class="mat-button">
                    <span class="mat-button-wrapper">Terms of Service</span></a>
                </div>
                <div>
                    <a href="https://polarbuilder.com/legal/privacy.html" class="mat-button">
                      <span class="mat-button-wrapper">Privacy Policy</span></a>
                  </div>
            </div>

                

                

                


                

        </div>
    </div>
    </footer>
    <!-- <a href="javascript:;" class="subscr" (click)="deleteProspect(currentUser);">Subscription</a> -->
    <custom-menu position="footer-secondary" class="second-menu" [horizontal]="true"></custom-menu>
</div>
<div class="row second-row">
    <div class="copyright first-col">
        {{ copyrightText }}
    </div>
    <div class="selectors second-col">
        <app-footer-lang-switcher></app-footer-lang-switcher>
    </div>
</div>