import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SubscriptionDialogModel } from './subscription-dialog';
import { UserIndexService } from '../footer-index.service';
import { User } from '@common/core/types/models/User';

@Component({
  selector: 'subscription-modal',
  templateUrl: './subscription-user-modal.component.html',
  styleUrls: ['./subscription-user-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class SubscriptionUserModalComponent {

    title: string;
    message: string;
    subscription: string;
    trial_ends_at: string;
    subCancel: any;
    uid: string;
    cancelResponse: any;
    is_true=true;

    constructor(public dialogRef: MatDialogRef<SubscriptionUserModalComponent>,
        @Inject(MAT_DIALOG_DATA) 
        public data: SubscriptionDialogModel,
        private userIndexService: UserIndexService
        ) {
        this.title = data.title;
        this.message = data.message;
        this.subscription = data.subscription;
        this.trial_ends_at = data.trial_ends_at;
        this.subCancel = data.cancel;
        this.uid = data.id;
    }

    async  unSubscribe(uid): Promise<any> {
        this.userIndexService.cancelSubscription(uid).then(data =>
        this.cancelResponse = data
    );

    this.subCancel=1;
        // if(this.cancelResponse.status==true){
        // }else{
        //     this.subCancel=0;
        // }

    }

    onDismiss(): void {
        this.dialogRef.close(false);
    }
}