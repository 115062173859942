<div class="card">
    <div class="card-header">
        Featured
    </div>
    <div class="card-body" *ngIf="subCancel == 0">

        <div class="row">
            <div class="col-md-6">
                <h3> Plans:</h3>
                <h4>{{subscription}}</h4>
            </div>
            <div class="col-md-6">
                <h3>Valid until :</h3>
                <h4>{{trial_ends_at}}</h4>
            </div>
        </div>
        <a (click)="unSubscribe(uid)" class="btn btn-primary unsub-user-button">Unsubscribe</a>
    </div>
    <div class="card-body" id="canceled" *ngIf="subCancel == 1">

        <div class="row">
            <div class="col-md-6">
                <h3> You have successfully unsubscribed</h3>
            </div>

        </div>
    </div>
</div>