import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';
import { ApiService } from 'src/app/api.service';

@Injectable({
  providedIn: 'root'
})
export class UserIndexService {

  data: any;
  gateway: any;
  public ticketsGetResponse = new BehaviorSubject([]);
  public addNewMonthResponse = new BehaviorSubject([]);
  public sticketDailyResponse = new BehaviorSubject([]);
  public sticketWeeklyResponse = new BehaviorSubject([]);
  public sticketMonthlyResponse = new BehaviorSubject([]);

  ticketsGetResponse$ = this.ticketsGetResponse.asObservable();
  addNewMonthResponse$ = this.addNewMonthResponse.asObservable();
  sticketDailyResponse$ = this.sticketDailyResponse.asObservable();
  sticketWeeklyResponse$ = this.sticketWeeklyResponse.asObservable();
  sticketMonthlyResponse$ = this.sticketMonthlyResponse.asObservable();

  constructor(private apiService: ApiService) { }

  async cancelSubscription(value): Promise<any> {
    let userID={'uid':value};
    await this.apiService.postData(`user_unsubscribe`,userID).then(res => res.json()).then((data) => {
      this.data = data;
    });
    return this.data;
  }

 
}